<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/08-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">IQVIA一站式多渠道客户营销管理平台</div>
            <div class="head-explain-content">提供数字化技术创新理念</div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="industry-box">
                <div class="card">
                    <img src="../assets/image/example/08-0销售效能管理.png"/>
                    <div class="title1">智慧监管执法</div>
                    <div class="title2">日常业务管理和执法办案信息管理工作平台</div>
                    <div class="title3" @click="jumpRout('/sales-effectiveness','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/08-0销售运营管理.png"/>
                    <div class="title1">智慧疾病控制</div>
                    <div class="title2">传染病智慧化多渠道监测系统</div>
                    <div class="title3" @click="jumpRout('/sales-operation','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/08-0学术会议管理.png"/>
                    <div class="title1">智慧健康体检</div>
                    <div class="title2">信息系统发布、在线预约、线下取号、院体检、报告打印、信息同步等功能与一体的智慧体检综合信息平台</div>
                    <div class="title3" @click="jumpRout('/academic-conference','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/08-0市场准入与商务运营.png"/>
                    <div class="title1">智慧医防协同</div>
                    <div class="title2">构建电子监控档案数据库、电子质历数据库、全员人口个案数据库</div>
                    <div class="title3" @click="jumpRout('/market-access','2')">了解详情</div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {

    },

    methods: {
        jumpRout(route,index){
            this.activeIndex = index
            localStorage.setItem('headId',index);
            this.$router.push(route)
        }
    },
};
</script>

<style lang="less" scoped>
.industry-box{
    width: 139rem;
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 4rem 0 0 26.1rem;
    .card{
        width: 33.2rem;
        height: 41rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 0px rgba(231,231,231,0.5);

        margin-top: 4rem;
    }
    img{
        width: 29.2rem;
        height: 20rem;
        margin-top: 2rem;
    }
    .title1{
        height: 2.8rem;
        font-size: 2.8rem;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #000000;
        line-height: 4.2rem;
        padding: 2rem;
        text-align: left;
    }
    .title2{
        height: 3.4rem;
        width: 29.2rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        padding: 2rem;
        padding-top: 1.5rem;
        text-align: left;
    }
    .title3{
        height: 3rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 30px;
        padding-left: 2rem;
        margin-top: 3rem;
        text-align: left;

        cursor: pointer;
    }
    .card:hover{
        transform: scale(1.05);
        transition:all 0.5s;
    }

}
</style>
